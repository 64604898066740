<template>
  <div class="cart margin-content" style="background-color: white">
    <div class="container pb-4">
      <div class="dc-header mb-4">
        <b-container fluid class="d-flex align-items-center font-title">
          <router-link
            :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
          >
            <div>ショップ</div>
          </router-link>
          <b-icon icon="chevronRight" class="mx-3"></b-icon>
          <router-link :to="{ name: '' }">
            <div>カート</div>
          </router-link>
        </b-container>
      </div>
      <CRow v-if="listCart.length">
        <CCol>
          <div class="h2 mb-3 font-title">カート</div>
          <div class="list-cart">
            <div class="cart-item mt-3">
              <!-- <div class="name-author">
                {{ value.last_name + " " + value.first_name }}
              </div> -->
              <div
                class="version-sp cart-item-content p-3 position-relative"
                style="height: auto"
              >
                <div>
                  カート小計（{{ numContent }}個の商品）：￥{{
                    totalPrice && Number(totalPrice).toLocaleString("ja")
                  }}（税込）
                </div>
                <!--<div>
                  加算ポイント：{{
                    Number(totalPointBonus).toLocaleString("ja")
                  }}pt（1pt=1円相当）
                </div>-->
              </div>
              <div
                class="cart-item-content p-3 position-relative"
                style="height: calc(200px px + 2rem)"
                v-for="(content, index) in listCart"
                :key="index"
              >
                <div class="status-payment" v-if="content.payment_method === 1">
                  定期販売
                </div>
                <div class="info d-flex justify-content-start">
                  <div
                    class="cart-img d-flex justify-content-center align-items-center"
                    v-bind:class="{ 'no-img': !content.image_url }"
                  >
                    <img
                      v-if="content.image_url"
                      :src="`${urlBackend}/storage/${content.image_url}`"
                      alt=""
                    />
                    <div
                      class="h5 text-dark px-3"
                      v-else
                      style="
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                      "
                    >
                      {{ content.title }}
                    </div>
                  </div>
                  <div
                    class="cart-detail pl-3 d-flex align-items-start flex-column h-100"
                  >
                    <div>
                      <div class="cart-title h4">
                        {{ content.title }}
                      </div>
                    </div>
                    <div
                      class="description-content text-truncate-title d-flex pb-2"
                      @click="goToDetailAuthor(content.author_id)"
                    >
                      <div class="content-img-author">
                        <img
                          v-if="content.avatar"
                          :src="`${urlBackend}/${content.avatar}`"
                          alt=""
                          loading="lazy"
                        />
                        <img v-else :src="`${no_image}`" />
                      </div>
                      <p class="ml-2 my-auto">{{ content.name }}</p>
                    </div>
                    <div>
                      <div class="cart-title price h4 d-flex">
                        <div
                          class="mr-5"
                          v-if="content.sale_basic.column[0].product_price"
                        >
                          <div
                            v-if="
                              content.sale_basic.column[0].sale_format ==
                              paymentType.subscription
                            "
                          >
                            {{
                              Math.round(
                                Number(
                                  parseInt(
                                    content.sale_basic.column[0].product_price
                                  ) + parseInt(content.sale_basic.column[0].fee)
                                )
                              ).toLocaleString("ja")
                            }}円 / {{ content.sale_basic.column[0].addBill
                            }}{{
                              content.sale_basic.column[0].billInternal == 1
                                ? "日"
                                : content.sale_basic.column[0].billInternal == 2
                                ? "週"
                                : content.sale_basic.column[0].billInternal == 3
                                ? "ヶ月"
                                : "年"
                            }}（税込）
                          </div>
                          <div v-else>
                            {{
                              content.sale_basic.column[0].fee
                                ? new Intl.NumberFormat("ja-JP", {
                                    style: "currency",
                                    currency: "JPY",
                                    currencyDisplay: "symbol",
                                  }).format(
                                    parseInt(
                                      content.sale_basic.column[0].product_price
                                    ) +
                                      parseInt(content.sale_basic.column[0].fee)
                                  )
                                : new Intl.NumberFormat("ja-JP", {
                                    style: "currency",
                                    currency: "JPY",
                                    currencyDisplay: "symbol",
                                  }).format(
                                    content.sale_basic.column[0].product_price
                                  )
                            }}
                          </div>
                          <!-- ￥{{
                            content.sale_basic.column[0].product_price
                              ? parseInt(
                                  content.sale_basic.column[0].product_price
                                ) + parseInt(content.sale_basic.column[0].fee)
                              : parseInt(
                                  content.sale_basic.column[0].product_price
                                )
                          }} -->
                        </div>
                        <!--<div>
                          付与ポイント：{{
                            Number(
                              content.point_bonus.total_point_bonus
                            ).toLocaleString("ja")
                          }}
                        </div>-->
                      </div>
                    </div>
                    <div
                      @click="confirmDeleteCart(content.cart_id)"
                      v-on:click.stop
                      class="cart-delete"
                    >
                      削除
                    </div>
                    <div class="btn-content version-pc">
                      <CButton
                        v-if="content.recommend && content.recommend.length > 0"
                        @click="showSameContent(content.recommend)"
                        class="btn-info mr-3"
                        >類似商品</CButton
                      >
                      <CButton
                        class="btn-payment btn-success"
                        @click="goToCheckout([content])"
                        >購入手続きへ</CButton
                      >
                    </div>
                  </div>
                </div>
                <div class="btn-content version-sp">
                  <CButton
                    v-if="content.recommend && content.recommend.length > 0"
                    @click="showSameContent(content.recommend)"
                    class="btn-info mr-3"
                    >類似商品</CButton
                  >
                  <CButton
                    class="btn-payment btn-success"
                    @click="goToCheckout([content])"
                    >購入手続きへ</CButton
                  >
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
    <div class="cart-empty" v-if="!listCart.length">
      <div class="cart-empty-text mb-3">
        {{ feedBack.DATA_EMPTY }}
      </div>
      <CButton @click="redirectStore" style="width: 160px !important"
        >今すぐストアへ行く</CButton
      >
    </div>

    <!-- modal loading payment -->
    <div>
      <b-modal
        id="loading-payment"
        hide-header
        hide-footer
        no-close-on-backdrop
      >
        <div class="text-center" style="margin: 10px">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="d-block text-center">
          <div>現在申し込み処理中です。</div>
          <div>次のページが表示されるまで、そのままお待ちください。</div>
        </div>
      </b-modal>
    </div>

    <!-- modal confirm delete -->
    <div>
      <b-modal id="delete-cart" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>本当に削除しますか？</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="deleteCart({ idCart, shop_id })"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-cart')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
    <!-- modal same content  -->
    <div>
      <b-modal size="lg" id="same-content" title="類似商品を探す" hide-footer>
        <template #modal-header>
          <h5
            id="same-content___BV_modal_title_"
            class="modal-title"
            style="position: absolute; left: 50%; transform: translateX(-50%)"
          >
            類似商品を探す
          </h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('same-content')"
          >
            ×
          </button>
        </template>
        <!-- <div v-if="content.length > 0" class="d-flex justify-content-center">
          <div
            v-for="(value, index) in content"
            :key="index"
            class="mr-3"
            style="width: 30%"
          >
            <img
              v-if="value.image_url"
              style="width: 100%"
              :src="`${urlBackend}/storage/${value.image_url}`"
              alt=""
            />
            <img v-else style="width: 100%" :src="`${no_image}`" alt="" />
            <div class="ml-2 mb-2">
              {{ value.title }}
            </div>
            <div class="ml-2 mb-2">
              {{ value.last_name + " " + value.first_name }}
            </div>
            <div class="ml-2 mb-2">
              ￥{{ Number(value.product_price).toLocaleString("ja") }}
            </div>
            <div>
              <button
                class="btn btn-success btn-add-cart btn"
                block
                @click="addCart(value.id)"
                >カートに入れる</button
              >
            </div>
          </div>
        </div> -->
        <div class="ct-home-content">
          <div class="container" style="width: unset">
            <b-row>
              <b-col
                v-for="(value, index) in content"
                class="mb-5 content-similar"
                cols="12"
                lg="4"
                :key="index"
                @click="returnDetailContent(value.id)"
              >
                <div class="ct-home-cart version-pc">
                  <div
                    class="cart-img border-bottom"
                    :class="{
                      'd-flex  align-items-center justify-content-center':
                        !value.image_url,
                    }"
                  >
                    <img
                      v-if="value.image_url"
                      :src="`${urlBackend}/storage/${value.image_url}`"
                      alt=""
                    />
                    <div
                      class="h5 text-dark px-3"
                      v-else
                      style="
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                      "
                    >
                      {{ value.title }}
                    </div>
                  </div>
                  <div class="title-content-comment">
                    <div class="card-name pt-3 pb-3 font-title-content-cart">
                      {{ value.title }}
                    </div>
                    <div
                      class="d-flex align-items-center pb-4 px-3"
                      @click="goToDetailAuthor(value.author_id)"
                      v-on:click.stop
                    >
                      <div class="card-avatar">
                        <img
                          :src="
                            value.avatar
                              ? `${urlBackend}/${value.avatar}`
                              : image_url_default
                          "
                          alt=""
                        />
                      </div>
                      <div class="card-nickName ml-2 text-dark text-des-long">
                        {{ value.last_name + " " + value.first_name }}
                      </div>
                    </div>
                    <div class="card-name pb-3">
                      ￥{{
                        value.sale_basic.column[0].fee
                          ? parseInt(value.sale_basic.column[0].product_price) +
                            parseInt(value.sale_basic.column[0].fee)
                          : parseInt(value.sale_basic.column[0].product_price)
                      }}
                    </div>
                    <div class="card-btn pb-3">
                      <b-button
                        v-if="check(value.id)"
                        class="btn-secondary"
                        disabled
                        v-on:click.stop
                      >
                        カートに追加済み
                      </b-button>
                      <button
                        :name="`add_content_` + `${value.id}`"
                        class="btn-success btn"
                        v-else
                        :disabled="checkloading(value.id)"
                        @click="addCart(value.id)"
                        v-on:click.stop
                      >
                        <b-spinner
                          v-if="checkloading(value.id)"
                          small
                        ></b-spinner>
                        <span>カートに入れる</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="version-sp content-cart-sp">
                  <div class="d-flex info">
                    <div class="image">
                      <img
                        v-if="value.image_url"
                        :src="`${urlBackend}/storage/${value.image_url}`"
                        alt=""
                      />
                      <div class="h5 text-dark" v-else>
                        {{ value.title }}
                      </div>
                    </div>
                    <div class="info-content">
                      <div
                        class="card-name px-3 pt-1 pb-1 font-title-content-cart"
                      >
                        {{ value.title }}
                      </div>
                      <div class="d-flex align-items-center pb-1 pl-3 author">
                        <div class="card-avatar">
                          <img
                            :src="
                              value.avatar
                                ? `${urlBackend}/${value.avatar}`
                                : image_url_default
                            "
                            alt=""
                          />
                        </div>
                        <div class="card-nickName ml-2 text-dark text-des-long">
                          {{ value.last_name + " " + value.first_name }}
                        </div>
                      </div>
                      <div class="card-name pl-3 pt-1 pb-1">
                        ￥{{
                          value.sale_basic.column[0].fee
                            ? parseInt(
                                value.sale_basic.column[0].product_price
                              ) + parseInt(value.sale_basic.column[0].fee)
                            : parseInt(value.sale_basic.column[0].product_price)
                        }}
                      </div>
                      <div class="card-btn pb-3 pl-3">
                        <b-button
                          class="btn-secondary"
                          style="min-width: 125px"
                          v-if="value.is_added_cart"
                          disabled
                          v-on:click.stop
                        >
                          カートに追加済み
                        </b-button>
                        <button
                          :name="`add_content_` + `${value.id}`"
                          class="btn-success btn"
                          style="min-width: 125px"
                          v-else
                          @click="addCart(value.id)"
                          v-on:click.stop
                        >
                          カートに入れる
                        </button>
                        <button
                          class="btn-info mt-2 btn"
                          style="min-width: 125px"
                          @click="goToDetailContent(value.id)"
                          v-on:click.stop
                        >
                          詳細
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";
import { FeedBack } from "@/utils/feedback.js";
import no_image from "@/assets/img/content-default.png";
import image_url_default from "@/assets/img/content-default.png";
import moment from "moment";

export default {
  name: "Cart",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      urlBackend: Constants.URL_BE,
      no_image: no_image,
      idCart: null,
      numContent: 0,
      totalPrice: 0,
      totalPointBonus: 0,
      feedBack: FeedBack,
      content: [],
      image_url_default,
      saleMethod: Constants.CONTENT_TYPE,
      checkData: null,
      listContentCart: [],
      paymentType: Constants.PAYMENT_TYPE,
    };
  },
  created() {
    // const { params } = this.$route;
    // const { shopId } = params;
    this.$store.commit("set", ["isShowMenu", ""]);
    this.getListCart({
      shop_id: this.shop_id,
    });
  },
  computed: {
    ...mapGetters([
      "listCart",
      "success",
      "error",
      "message",
      "pointOfUser",
      "paymentInfos",
      "detailShop",
      "isDeleteSuccessCart",
    ]),
  },
  watch: {
    isDeleteSuccessCart() {
      if (this.isDeleteSuccessCart) {
        this.$toasted.clear();
        this.$toasted.success("コンテンツをカートから削除しました。");
        this.$store.commit("set", ["isDeleteSuccessCart", false]);
        this.$store.commit("set", ["message", ""]);
        // const { params } = this.$route;
        // const { shopId } = params;
        this.getListCart({
          shop_id: this.shop_id,
        });
        this.$bvModal.hide("delete-cart");
      }
    },
    success() {
      if (this.success) {
        this.$toasted.clear();
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        // const { params } = this.$route;
        // const { shopId } = params;
        this.getListCart({
          shop_id: this.shop_id,
        });
        this.$bvModal.hide("same-content");
      }
    },
    error() {
      if (this.error) {
        this.$toasted.clear();
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.$bvModal.hide("delete-cart");
      }
    },
    listCart() {
      if (this.listCart && this.listCart.length > 0) {
        let baseDefault = this.$root.$refs.baseDefault;
        this.listContentCart = this.listCart;
        this.numContent = this.listCart.length;
        this.listCart.forEach((element) => {
          element.title = baseDefault.changeSentence(element.title);
          // element.list_cart.forEach((item) => {
          if (element.recommend) {
            element.recommend.forEach((content) => {
              content.title = baseDefault.changeSentence(content.title);
              if (
                content.sale_other &&
                content.sale_other.sale_method === this.saleMethod.pre_sale &&
                content.sale_other.pre_sale_start_date <=
                  moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss") &&
                element.sale_other.pre_sale_end_date >=
                  moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss")
              ) {
                content.product_price = content.sale_other.pre_sale_price;
              }
            });
            if (element.payment_method !== 1) {
              element["has_status_2"] = true;
            }
            if (
              element.sale_basic &&
              parseInt(element.sale_basic.column[0].priceOverTime) > 0 &&
              element.sale_basic.column[0].fromTimeRelease <=
                moment(String(new Date())).format("YYYY-MM-DD HH:mm") &&
              element.sale_basic.column[0].toTimeRelease >=
                moment(String(new Date())).format("YYYY-MM-DD HH:mm")
            ) {
              element.sale_basic.column[0].product_price = parseInt(
                element.sale_basic.column[0].priceOverTime
              );
            }
            if (
              element.sale_other &&
              element.sale_other.sale_method === this.saleMethod.pre_sale &&
              element.sale_other.pre_sale_start_date <=
                moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss") &&
              element.sale_other.pre_sale_end_date >=
                moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss")
            ) {
              element.product_price = element.sale_other.pre_sale_price;
            }
          }
          this.totalPrice += element.sale_basic.column[0].fee
            ? parseInt(element.sale_basic.column[0].product_price) +
              parseInt(element.sale_basic.column[0].fee)
            : parseInt(element.sale_basic.column[0].product_price);
          this.totalPointBonus +=
            element.point_bonus && element.point_bonus.total_point_bonus
              ? element.point_bonus.total_point_bonus
              : 0;
          // });
        });
      }
    },
  },
  methods: {
    ...mapActions({ getListCart: "getListCart" }),
    ...mapActions({ deleteCart: "deleteCart" }),
    ...mapActions({ getListContent: "getListContent" }),
    ...mapActions({ addToCart: "addToCart" }),
    moment,
    async addCart(idContent) {
      this.checkData = idContent;
      const shop_id = this.shop_id;
      const formData = {
        content_id: [idContent],
        shop_id: this.shop_id,
        title: this.$route.meta.title,
        referrer_url: window.location.href,
        url: window.document.activeElement.baseURI,
        id_button: "add_content_" + idContent,
        add_cart_url:
          window.location.origin +
          this.$router.resolve({
            name: "content detail",
            params: {
              shopId: this.shop_id,
              id: idContent,
            },
            query: { keyword: "" },
          }).href,
      };
      const dataReturn = await this.$store.dispatch("addToCart", formData);
      if (dataReturn) {
        if (dataReturn.success) {
          const checkCart = await this.$store.dispatch("getListCart", {
            shop_id,
          });
          if (checkCart) {
            this.checkData = null;
          }
        } else {
          this.checkData = null;
        }
      }
    },
    checkloading(index) {
      if (this.checkData == index) return true;
      else return false;
    },
    showSameContent(content) {
      this.content = content;
      this.$bvModal.show("same-content");
    },
    formatNumber(data) {
      return Number(data).toLocaleString("ja");
    },
    goToContentDetail(idContent) {
      const { params } = this.$route;
      const { shopId } = params;
      this.$router.push({
        name: shopId ? "content detail" : "content detail domain",
        params: { id: idContent, shopId: this.shop_id },
      });
    },
    confirmDeleteCart(idCart) {
      this.idCart = idCart;
      this.$bvModal.show("delete-cart");
    },
    redirectStore() {
      const shop_name = localStorage.getItem(Constants.SHOP_NAME);
      this.$router.push({ name: "store", params: { shopName: shop_name } });
    },
    async goToCheckout(listContent) {
      // 外部決済（入力フォームをスキップする場合は購入へリダイレクト）
      let sale_basic_column = listContent[0].sale_basic.column[0];
      if (sale_basic_column.defaultPayment.length === 1 && sale_basic_column.defaultPayment.includes(3) && sale_basic_column.externalPaymentUrlSkip) {
        // 外部決済直行
        const self = this;
        const url = `/${Urls.DIRECT_EXTERNAL_PAYMENT}`;
        this.$bvModal.show("loading-payment");
        let cartData = {
          cart_id: listContent[0].cart_id,
          shop_id: this.shop_id,
          //log_data
          title: self.$route.meta.title,
          add_cart_url: window.location.origin + self.$route.fullPath,
        }
        await Api.userRequestServer
          .post(url, cartData)
          .then((response) => {
            const { data } = response;
            console.log('res data', data);
            if (data.success) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.success(data.message);
              localStorage.setItem('external_payment_token', data.data.externalPaymentToken);
              window.location.href = data.data.redirectUrl;
            } else {
              this.$toasted.error(data.message);
              this.$bvModal.hide("loading-payment");
            }
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(
                "決済時にシステムエラーが発生しました。再度お試しください。"
              );
            }
          });
      } else {
        // 入力フォーム
        localStorage.setItem(Constants.LIST_CONTENT, JSON.stringify(listContent));
        this.$router.push({
          name: this.$route.params.shopId ? "paymentBuy" : "paymentBuy domain",
          params: { id: listContent[0].content_id },
        });
      }
    },
    goToDetailContent(content_id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "content detail"
          : "content detail domain",
        params: {
          shopId: this.shop_id,
          id: content_id,
        },
      });
    },
    check(id) {
      const listCheck = this.listContentCart;
      var check = false;
      listCheck.filter(function (ele) {
        if (id == ele.content_id) {
          check = true;
          return;
        }
      });
      return check;
    },
    goToDetailAuthor(author_id) {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "author detail",
          params: {
            shopId: this.shop_id,
            id: author_id,
          },
        });
      } else {
        this.$router.push({
          name: "author detail domain",
          params: {
            id: author_id,
          },
        });
      }
    },
    returnDetailContent(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "content detail"
          : "content detail domain",
        params: {
          id: id,
        },
        query: { keyword: "" },
      });
    },
  },
};
</script>
<style lang="scss">
.cart {
  background-color: #f5f5f5;
  min-height: calc(100vh - 280px);
  .cart-empty {
    width: 100%;
    min-height: calc(100vh - 280px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button {
      background-color: #fe3834;
      color: #fff;
    }
  }
  .list-cart {
    .cart-item {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      .cart-item-content {
        .info {
          height: 100%;
          @media (max-width: 449px) {
            height: auto;
          }
        }
        border-bottom: 1px solid #c5c5c5;
        height: calc(200px + 2rem);
        @media (max-width: 449px) {
          height: calc(150px + 2rem);
        }
        div {
          align-items: center;
          @media (max-width: 449px) {
            align-items: start;
          }
          .cart-img {
            width: 200px;
            @media (max-width: 449px) {
              width: 100px;
              height: 100px;
            }
            height: 100%;
            .block-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .cart-delete {
          .btn-trash {
            background: #c5c5c5;
          }
        }
      }
      .cart-more {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-top: 1px solid #f2f2f2;
      }
    }
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      width: 95%;
      display: flex;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .checkout-btn {
    display: block;
    margin: 0 auto;
    width: 30%;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
}
.checkout-screen {
  background-color: #f5f5f5;
  padding-bottom: 10px;
  .card {
    background: #7a95c6;
    color: white;
    justify-content: center;
    margin: auto;
  }
  #stripe-element-mount-point {
    border: 1px solid blue;
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .custom-button-paypal {
    background: #ffc439;
    border-radius: 4px;
    width: 90%;
    margin: auto;
    &:hover {
      cursor: pointer;
      background: #fcb208;
    }
  }
  .checkout-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #635bff;
  }
  .cancel-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
  .showmore-btn {
    width: auto;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #e19c9b;
  }
  .input-card {
    margin-left: 40px;
    .check-box-card {
      margin: 5px;
      margin-right: 10px;
    }
    .select-card {
      width: 240px;
    }
  }
}
.model-foote {
  margin-top: 10px;
}
.ct-home {
  &-tabs {
    height: 100px;
    background: #d5d5d5;
  }
  &-search {
    position: relative;
    width: 400px;
    height: 100%;
    input {
      width: 100%;
    }
    .search {
      &-input {
        padding-right: 35px;
      }
      &-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  &-tab {
    .custom-tab {
      width: 120px;
      height: 60px;
      border-radius: 10px 10px 0 0;
      // &.active {
      //   background: #ebedef;
      // }
    }
  }
  &-filter {
    padding: 30px 0;
    .filter-btn {
      button {
        padding: 20px 30px;
        border-radius: 10px;
      }
    }
    .filter-icon {
      font-size: 24px;
    }
  }
  &-link {
    a {
      font-size: 18px;
      color: #000;
      text-decoration: underline;
    }
  }
  &-allOption {
    // height: 98px;
    .allOption {
      &-close {
        width: 25px;
        height: 25px;
        background: rgb(110, 110, 110);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }
    }
  }
  &-modalFilter {
    .modalFilter {
      &-backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
        background-color: #0000008f;
        z-index: 10;
      }
      &-content {
        top: 50%;
        left: 50%;
        max-width: calc(100% - 20px);
        margin: auto;
        transform: translate(-50%, -50%);
        background: #fff;
        z-index: 11;
        border: 1px solid #b5b5b5;
        border-radius: 10px;
      }
      &-header {
        padding: 25px 0;
        border-bottom: 1px solid #b5b5b5;
        font-size: 20px;
      }
      &-item {
        padding: 25px 20px;
        border-top: 1px solid #b5b5b5;
        width: 700px;
        max-width: 100%;
        cursor: pointer;
        &:hover {
          background: #e3e3e3;
        }
      }
      &-search {
        padding: 20px;
        padding-top: 0;
        .ct-home-search {
          width: 100%;
          input {
            height: 50px;
          }
          .search-icon {
            right: 10px;
            font-size: 20px;
          }
        }
      }
      &-close {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &-itemIcon {
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        font-size: 20px;
      }
      &-check {
        color: #46c000;
      }
    }
  }
  &-cart {
    width: 200px;
    border: 1px solid #f6f5f5;
    @media (max-width: 992px) {
      display: flex;
      width: 100%;
      border: none;
      border-bottom: 1px solid #b5b5b5;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    border: 1px solid #f6f5f5;
    .card {
      &-img {
        height: 180px;
        width: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &-avatar {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &-btn {
        display: flex;
        justify-content: center;
      }
    }
    .cart-img {
      height: 200px;
      // width: 200px;
      background-color: #dee9f0;
      @media (max-width: 449) {
        width: 100px;
        height: 100px !important;
      }
    }
  }
}
#checkout {
  position: fixed;
  bottom: 0;
  z-index: 1;
}
.status-payment {
  position: absolute;
  right: 10px;
  width: 100px;
  text-align: center;
  border-radius: 5px;
  background-color: red;
  color: #fff;
}
.btn-content {
  position: absolute;
  right: 10px;
  width: 100px;
  bottom: 10px;
  display: flex;
  height: 40px;
  justify-content: right;
  .btn-info {
    min-width: 100px;
    background-color: #4472c4;
    color: #fff;
  }
  .btn-payment {
    min-width: 110px;
    color: #fff;
  }
  @media (max-width: 449px) {
    position: unset;
    width: 100%;
    justify-content: start;
    margin-top: 20px;
  }
}
.payment {
  height: 80px;
  text-align: right;
  .btn-payment {
    min-width: 110px;
    color: #fff;
    height: 40px;
    margin-top: 20px;
    margin-right: 10px;
    width: 200px;
  }
}
.btn-add-cart {
  height: 30px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 0;
  min-width: 100px;
}
.cart-delete:hover {
  cursor: pointer;
}
.dc-header {
  padding-top: 20px;
  font-size: 18px;
  a {
    color: #000;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }
}
.cart-title {
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (max-width: 449px) {
    display: block !important;
  }
}
.content-cart-sp {
  box-shadow: 0px 3px 6px #7681929c;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 20px;
  .info {
    align-items: center;
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(222, 233, 240);
      height: 100px;
      min-width: 100px;
      max-width: 100px;
      img {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .info-content {
      .author {
        .card-avatar {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.font-title-content-cart {
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.content-img-author {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.text-des-long {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 992px) {
  .title-content-comment {
    width: 45%;
  }
  .info-content {
    max-width: 70%;
  }
}
@media (max-width: 320px) {
  .info-content {
    max-width: 55% !important;
  }
}
@media (max-width: 400px) {
  .info-content {
    max-width: 60%;
  }
  .content-cart-sp {
    .card-btn {
      button {
        min-width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}
.content-similar:hover {
  cursor: pointer;
}
</style>
